import React from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faFacebook, faInstagram } from "@fortawesome/free-brands-svg-icons";
import ciasta_video from "../../videos/ciasta.mp4";
import "./SweetBuffet.scss";

function SweetBuffet() {
  const followURL = (url) => {
    window.open(url, "_blank");
  };

  return (
    <section className="sweet-buffet">
      <h1>
        Cukiernia tworzona z<br />
        pasją i tradycją od 1980 r.
      </h1>
      <video playsInline autoPlay muted>
        <source src={ciasta_video} type="video/mp4" />
      </video>
      <section className="sweet-buffet-social-media">
        <span
          onClick={() =>
            followURL("https://www.facebook.com/ciastkarniamarcepanek")
          }
        >
          <FontAwesomeIcon icon={faFacebook} color="#e8e4d9" size="3x" />
        </span>
        <span
          onClick={() =>
            followURL("https://www.instagram.com/ciastkarniamarcepanek")
          }
        >
          <FontAwesomeIcon icon={faInstagram} color="#e8e4d9" size="3x" />
        </span>
      </section>
    </section>
  );
}
export default SweetBuffet;
