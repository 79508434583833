import React, { useState } from "react";
import PropTypes from "prop-types";
import $ from "jquery";
import Modal from "react-modal";

const customStyles = {
  content: {
    width:
      $(window).width() / parseFloat($("body").css("font-size")) <= "32"
        ? "90%"
        : "60%",
    top: "50%",
    left: "50%",
    right: "auto",
    bottom: "auto",
    color: "#E8E4D9",
    padding: "0",
    margin: "0",
    border: "none",
    borderRadius: "12px",
    backgroundColor: "transparent",
    transform: "translate(-50%, -50%)",
  },
};

const Thumb = (props) => {
  const [modalIsOpen, setIsOpen] = useState(false);
  function openModal() {
    setIsOpen(true);
  }
  function closeModal() {
    setIsOpen(false);
  }
  return (
    <div key={props.opacity + props.title} className={props.classes}>
      <img
        src={props.src}
        alt={props.alt}
        title={props.title}
        style={{ opacity: props.opacity }}
        onClick={openModal}
      />
      <Modal
        style={customStyles}
        ariaHideApp={false}
        isOpen={modalIsOpen}
        onRequestClose={closeModal}
      >
        <img
          style={{ width: "100%", height: "100%", objectFit: "contain" }}
          src={props.src}
          alt=""
        />
      </Modal>
    </div>
  );
};

Thumb.propTypes = {
  alt: PropTypes.string,
  title: PropTypes.string,
  classes: PropTypes.string,
  src: PropTypes.string.isRequired,
  opacity: PropTypes.string,
};

export default Thumb;
