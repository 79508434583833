import { React } from "react";
import "./styles.scss";

const feedback = [
  {
    adres: "ul. Adama Asnyka 18 \n 63-400 Ostrów Wielkopolski",
    h: "Pon – pt: 8:00 – 18:00 \n Sobota: 8:00 – 15:00 \n Niedziela: 9:00 – 14:00",
  },
  {
    adres: "ul. Raszkowska 26 \n 63-400 Ostrów Wielkopolski",
    h: "Pon – pt: 9:00 – 17:30 \n Sobota: 8:00 – 15:00 \n Niedziela: 9:00 – 14:00",
  },
];

const LocsPage = () => {
  return (
    <div className="loc-wrapper">
      <h2 id="location">Lokalizacja sklepów</h2>
      <section className="loc">
        <iframe
          src="https://www.google.com/maps/d/embed?mid=1R7ev8pWRpmDLl-4YHjpm78XBk3Vb6vI&ehbc=2E312F"
          width="600"
          height="450"
          style={{ border: 0 }}
          allowFullScreen=""
          loading="lazy"
          referrerPolicy="no-referrer-when-downgrade"
        ></iframe>
        <main className="loc-details">
          <span className="loc-details-single">
            <div>
              <h5>Adres</h5>
              <p>{feedback[0].adres}</p>
            </div>
            <div>
              <h5>Godziny otwarcia</h5>
              <p>{feedback[0].h}</p>
            </div>
          </span>
          <span className="loc-details-single">
            <div>
              <h5>Adres</h5>
              <p>{feedback[1].adres}</p>
            </div>
            <div>
              <h5>Godziny otwarcia</h5>
              <p>{feedback[1].h}</p>
            </div>
          </span>
        </main>
      </section>
    </div>
  );
};

export default LocsPage;
