import React, { useEffect } from "react";
import "./styles.scss";
import torty from "./../../videos/torty.mp4";
import monoporcje from "./../../videos/monoporcje.mp4";
import ciasta_kruche from "./../../videos/ciastka.mp4";
import tartaletki from "./../../videos/tartaletki.mp4";
import ciasta from "./../../videos/ciasta.mp4";
import { withRouter } from "react-router-dom";

const products = [
  {
    name: "Torty",
    desc:
      "Najpierw jest wizja klienta, potem to właśnie my ją zamieniamy w rzeczywistość. Zaczynamy od podniebienia, czyli gustów smakowych, kończymy na oprawie wizualnej.",
  },
  {
    name: "Monoporcje",
    desc:
      "Świetne sprawdza się jako jedna z propozycji dla Twojego słodkiego bufetu. Idealna kompozycja naturalnego orzeźwienia oraz słodkiej nuty. Podbijają serca i podniebienia każdego.",
  },
  {
    name: "Tartaletki",
    desc:
      "Naturalne kruche ciasto na bazie masła. Połączenie domowych tradycji z elementami nowoczesnego cukiernictwa oraz smakiem świeżości za sprawą soczystych owoców.",
  },
  {
    name: "Ciasta",
    desc:
      "Tradycyjna receptura, doskonały smak, staranna selekcja najwyższej jakości składników i dbałość o estetykę wykonania. Przygotowywane przez naszych mistrzów cukiernictwa według naszych oryginalnych receptur. Spróbuj wypieków na świeżych owocach prosto od lokalnych gospodarzy, ciast na prawdziwym świeżym maśle oraz wielu innych naszych propozycji.",
  },
  {
    name: "Ciastka kruche",
    desc:
      "Przypomnij sobie tą radość i smak z dzieciństwa podczas jedzenia świeżych wypieków prosto z pieca. Wypiekane z najwyższą starannością o smak oraz na bazie tradycyjnych receptur.",
  },
];

const Category = (props) => {
  const nextPath = (path) => {
    props.props.history.push(path);
  };

  useEffect(() => {
    const videos = document.querySelectorAll("[id^='offer-category-video-']");
    const buttons = document.querySelectorAll(
      "[id^='offer-category-other-button-']"
    );
    const observer = new IntersectionObserver((entries) => {
      entries.forEach(function (entry) {
        if (!entry.isIntersecting) {
          if (entry.target.id === "offer-category-other-button-0") {
            videos[0].pause();
          } else if (entry.target.id === "offer-category-other-button-1") {
            videos[1].pause();
          } else if (entry.target.id === "offer-category-other-button-2") {
            videos[2].pause();
          } else if (entry.target.id === "offer-category-other-button-3") {
            videos[3].pause();
          } else if (entry.target.id === "offer-category-other-button-4") {
            videos[4].pause();
          }
        } else {
          if (entry.target.id === "offer-category-other-button-0") {
            videos[0].play();
            if (!videos[1].paused) {
              videos[1].pause();
            }
            if (!videos[2].paused) {
              videos[2].pause();
            }
            if (!videos[3].paused) {
              videos[3].pause();
            }
            if (!videos[4].paused) {
              videos[4].pause();
            }
          }
          if (entry.target.id === "offer-category-other-button-1") {
            videos[1].play();
            if (!videos[0].paused) {
              videos[0].pause();
            }
            if (!videos[2].paused) {
              videos[2].pause();
            }
            if (!videos[3].paused) {
              videos[3].pause();
            }
            if (!videos[4].paused) {
              videos[4].pause();
            }
          }
          if (entry.target.id === "offer-category-other-button-2") {
            videos[2].play();
            if (!videos[0].paused) {
              videos[0].pause();
            }
            if (!videos[1].paused) {
              videos[1].pause();
            }
            if (!videos[3].paused) {
              videos[3].pause();
            }
            if (!videos[4].paused) {
              videos[4].pause();
            }
          }
          if (entry.target.id === "offer-category-other-button-3") {
            videos[3].play();
            if (!videos[0].paused) {
              videos[0].pause();
            }
            if (!videos[1].paused) {
              videos[1].pause();
            }
            if (!videos[2].paused) {
              videos[2].pause();
            }
            if (!videos[4].paused) {
              videos[4].pause();
            }
          }
          if (entry.target.id === "offer-category-other-button-4") {
            videos[4].play();
            if (!videos[0].paused) {
              videos[0].pause();
            }
            if (!videos[1].paused) {
              videos[1].pause();
            }
            if (!videos[2].paused) {
              videos[2].pause();
            }
            if (!videos[3].paused) {
              videos[3].pause();
            }
          }
        }
      });
    }, {});
    buttons.forEach((btn) => observer.observe(btn));
  }, []);

  return (
    <section className="offer-category">
      <section className={`offer-category-other-` + props.number}>
        <h2 id={`offer-category-other-button-` + props.number}>
          {products[props.number].name}
        </h2>
        <article>{products[props.number].desc}</article>
        <span onClick={() => nextPath("/Oferta")}>Więcej</span>
      </section>
      <video
        playsInline
        id={`offer-category-video-` + props.number}
        muted
        className={`offer-category-video-` + props.number}
      >
        <source src={props.image} type="video/mp4" />
      </video>
    </section>
  );
};

const OfferPage = (props) => {
  return (
    <>
      <div className="wrapper">
        <h2>Tworzymy z pasją</h2>
        <section className="offer">
          <Category number={0} image={torty} props={props} />
          <Category number={1} image={monoporcje} props={props} />
          <Category number={2} image={tartaletki} props={props} />
          <Category number={3} image={ciasta} props={props} />
          <Category number={4} image={ciasta_kruche} props={props} />
        </section>
      </div>
    </>
  );
};

export default withRouter(OfferPage);
