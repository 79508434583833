import { React, useState, useEffect } from "react";
import "./styles.scss";

const NewsView = () => {
  const [email, setEmail] = useState("");

  useEffect(() => {
    const script = document.createElement("script");
    script.src = "https://smtpjs.com/v3/smtp.js";
    script.async = true;
    document.body.appendChild(script);
    return () => {
      document.body.removeChild(script);
    };
  }, []);

  function handleSubmitButton() {
    var inputFields = document.querySelectorAll("[id='required-field']");
    var i;
    for (i = 0; i < inputFields.length; i++) {
      if (inputFields[i].value.length === 0) {
        inputFields[i].setCustomValidity("Pole jest wymagane");
      } else {
        inputFields[i].setCustomValidity("");
      }
      if (
        inputFields[i].id === "required-field" &&
        inputFields[i].value.length > 0
      ) {
        handleEmailInput(inputFields[i]);
      }
    }
  }

  function handleSubmit(e) {
    e.preventDefault();
    window.Email.send({
      Host: "vps-91419065.vps.ovh.net",
      Username: "marcepanek",
      Password: "Twins=12",
      To: "ciastkarnia.marcepanek@gmail.com",
      From: "marcepanek@vps-91419065.vps.ovh.net",
      Subject: "Marcepanek.pl  - zapis do subskrypcji - " + email,
      Body: "Zapisuję się do newslettera. Mój email to: " + email,
    });
    setEmail("");
    alert("E-mail został wysłany!");
  }

  function handleEmailInput(inputField) {
    var re = /\S+@\S+\.\S+/;
    if (re.test(inputField.value) === false) {
      inputField.setCustomValidity(
        "Niepoprawny format e-mail, przykład: jan.nowak@gmail.com"
      );
    } else {
      inputField.setCustomValidity("");
    }
  }

  return (
    <div className="newsletter">
      <section className="newsletter-text">
        <h4>Chcesz być na bieżąco?</h4>
        <article>
          Podaj swój adres email, aby jako pierwszy dowiadywać się o nowościach
          i promocjach. Gdy tylko będziemy mieli do zaoferowania coś
          specjalnego, napiszemy Ci o tym na podany adres email.
        </article>
      </section>
      <section className="newsletter-input">
        <form className="newsletter-input-form" onSubmit={handleSubmit}>
          <input
            id="required-field"
            name="email"
            type="text"
            value={email}
            placeholder="Wpisz adres e-mail..."
            onChange={(e) => setEmail(e.target.value)}
          />

          <input
            onClick={handleSubmitButton}
            type="submit"
            value="Zapisz się"
          ></input>
        </form>
      </section>
    </div>
  );
};

export default NewsView;
