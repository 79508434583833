import React from "react";
import { Route, Switch } from "react-router-dom";
import Navbar from "./components/Navbar/Navbar";
import MainPage from "./pages/MainPage";
import OfferPage from "./pages/OfferPage";
import CooperPage from "./pages/CooperPage";
import AboutUsPage from "./pages/AboutUsPage";
import ScrollToTop from "./ScrollToTop";

function Router() {
  return (
    <React.Fragment>
      <Switch>
        <Route path="/" component={Navbar} />
      </Switch>
      <Switch>
        <ScrollToTop>
          <Route exact path="/" component={MainPage} />
          <Route path="/Oferta" component={OfferPage} />
          <Route path="/Onas" component={AboutUsPage} />
          <Route path="/Współpraca" component={CooperPage} />
          <Route path="/Lokalizacje" component={MainPage} />
          <Route path="/Kontakt" component={MainPage} />
        </ScrollToTop>
      </Switch>
    </React.Fragment>
  );
}

export default Router;
