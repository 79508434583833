import PropTypes from "prop-types";
import React from "react";
import { connect } from "react-redux";
import {
  updateFilters,
  removeFilters,
  removeFilter,
} from "../../../services/filters/actions";
import { fetchProducts } from "../../../services/shelf/actions";
import Checkbox from "../../Checkbox";
import "./style.scss";

const groups = ["Torty", "Monoporcje", "Tartaletki", "Ciasta", "Ciastka"];

class Filter extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      isGroup: true,
    };
    this.getGroupQuantity = this.getGroupQuantity.bind(this);
  }
  static propTypes = {
    updateFilters: PropTypes.func.isRequired,
    filters: PropTypes.array.isRequired,
    fetchProducts: PropTypes.func.isRequired,
    sort: PropTypes.string,
    priceFrom: PropTypes.number,
    filterQuantity: PropTypes.number,
    productsList: PropTypes.array.isRequired,
    productsListUF: PropTypes.array.isRequired,
  };

  componentWillReceiveProps(nextProps) {
    if (nextProps.newFilter !== this.props.newFilter) {
      this.addFilter(nextProps.newFilter);
    }
    if (nextProps.filterToRemove !== this.props.filterToRemove) {
      this.removeFilter(nextProps.filterToRemove);
    }
  }

  getGroupQuantity(group) {
    let filteredItems = 0;

    const arrFiltered = this.props.productsListUF.filter(
      (f) => f.group === group
    );

    if (!!arrFiltered) {
      filteredItems = arrFiltered.length;
    }

    return filteredItems;
  }

  handleFetchProducts = (
    filters = this.props.filters,
    sort = this.props.sort,
    filterQuantity = this.props.filterQuantity,
    productsMariusz = this.props.productsListUF
  ) => {
    this.setState({ isLoading: true });
    this.props.fetchProducts(
      filters,
      sort,
      () => {
        this.setState({ isLoading: false });
      },
      filterQuantity,
      1,
      productsMariusz
    );
  };

  removeFilter = (objName) => {
    const { filters, updateFilters } = this.props;

    var index = filters.findIndex((f) => f[0] === objName);
    if (index >= 0) {
      filters.splice(index, 1);
      updateFilters(filters);
    }
  };

  handleFilter = (objName, filterVal, doFetch) => {
    const { filters } = this.props;

    if (filterVal === false || filterVal === 0) {
      const index = filters.findIndex((f) => f[0] === objName);
      if (index >= 0) {
        filters.splice(index, 1);

        this.removeFilter(objName);
      }
    } else {
      filters.push([objName, filterVal]);
    }
    this.props.updateFilters(filters);
    if (doFetch) {
      this.handleFetchProducts();
    }
  };

  removeFilters() {
    const { filters } = this.props;
    filters.length = 0;
    removeFilters(filters);
    this.props.updateFilters(filters);
    this.handleFetchProducts();
    this.setState({
      isGroup: true,
    });
    this.createCheckboxes();
  }

  createCheckbox = (label) => {
    const isFiltered = Array.isArray(
      this.props.filters.find((filt) => filt[0] === label)
    );
    return (
      <Checkbox
        classes="filters-available-size"
        objName={label}
        handleFilter={this.handleFilter}
        key={label}
        isFiltered={isFiltered}
        filters={this.props.filters}
        filterItems={this.getGroupQuantity(label)}
      />
    );
  };

  createCheckboxes = () => groups.map(this.createCheckbox);

  render() {
    return (
      <>
        <div className="filters">
          <div className="filters-up">
            <h4>FILTRY</h4>
            <span onClick={() => this.removeFilters()}>Wyczyść filtry</span>
          </div>
          <div className="filters-down">
            <p>Rodzaj produktu</p>
            <div className="filter-div-items">{this.createCheckboxes()}</div>
          </div>
        </div>
      </>
    );
  }
}

const mapStateToProps = (state) => ({
  newFilter: state.filterObj.filterToAdd,
  filterToRemove: state.filterObj.filterToRemove,
  sort: state.sort.type,
  products: state.shelf.products,
  filters: state.filterObj.filters,
  filterQuantity: state.filterQuantity.type,
  productsList: state.apiProducts.productsList,
  productsListUF: state.apiProductsUF.productsListUF,
});

export default connect(mapStateToProps, {
  updateFilters,
  removeFilter,
  fetchProducts,
})(Filter);
