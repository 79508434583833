import React from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { updateSort } from "../../../services/sort/actions";
import Selectbox from "../../Selectbox";
import "./style.scss";

const sortBy = [
  { value: "", label: "Wybierz" },
  { value: "lowestprice", label: "Rosnąco" },
  { value: "highestprice", label: "Malejąco" },
];

const Sort = ({ updateSort, sort }) => (
  <div className="sort">
    <p>Sortuj: </p>
    <Selectbox options={sortBy} handleOnChange={(value) => updateSort(value)} />
  </div>
);

Sort.propTypes = {
  updateSort: PropTypes.func.isRequired,
  sort: PropTypes.string.isRequired,
};

const mapStateToProps = (state) => ({
  sort: state.sort.type,
});

export default connect(mapStateToProps, { updateSort })(Sort);
