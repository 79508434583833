import React, { useState } from "react";
import "./styles.scss";
import imgHistory from "./../../images/history.jpg";
import imgHistorySmall from "./../../images/history-small.jpg";
import imgOwner1 from "./../../images/owner1.jpg";
import imgOwner2 from "./../../images/owner2.jpg";
import imgPlace from "./../../images/place.jpg";
import ContactUs from "../ContactUs/ContactUS";
import Footer from "../../components/Footer/Footer";
import $ from "jquery";

const AboutUsView = () => {
  const [years, setYearActive] = useState([
    {
      year: "1980",
      img: imgPlace,
      textHead: "Powstanie firmy",
      text:
        "Zbigniew Szczeblewski - właściciel - otwierają wraz z żoną Urszulą pierwszą cukiernię przy ul. Asnyka 18, gdzie odbywała się zarówno produkcja, jak i sprzedaż pierwszych tradycyjnych wyrobów.",
      isActive: "active",
    },
    {
      year: "1988 1990",
      img: "",
      textHead: "Rozbudowa",
      text:
        "Znaczny wzrost klientów oraz nowe pomysły na wyroby cukiernicze przyśpiesza budowę nowego zakładu produkcyjnego przy ul. Strzeleckiej 119, gdzie do dziś powstają obecnie dostępne w firmowych sklepach wyroby cukiernicze.",
      isActive: "",
    },
    {
      year: "1999",
      img: "",
      textHead: "Z pokolenia na pokolenie",
      text:
        "Do Marcepanka dołącza córka Aneta, która odpowiada za jakość wyrobów produkowanych w firmie oraz poszerzanie gamy produktów i selekcję składników wykorzystywanych do produkcji.",
      isActive: "",
    },
    {
      year: "2013",
      img: "",
      textHead: "Drugi sklep",
      text:
        "Kontynuacja tradycji i otwarcie drugiej, firmowej cukierni na Ostrowskim Śródmieściu przy ul. Raszkowskiej 26.",
      isActive: "",
    },
    {
      year: "2015",
      img: "",
      textHead: "Zmieniamy się",
      text:
        "Metamorfoza pierwszej cukierni czyli zmiana wnętrza sklepu firmowego przy ul. Asnyka 18 - nawiązanie do przedwojennego wystroju sklepu przy ulicy Raszkowskiej 26, w którym znajduje się druga firmowa cukiernia.",
      isActive: "",
    },
    {
      year: "2021",
      img: "",
      textHead: "Rebranding oraz cukiernia online",
      text:
        "Zmiana wizerunku marki Marcepanek oraz powstanie oficjalnej witryny internetowej powoduje pierwsze zamówienia składane online poprzez formularz kontaktowy na portalach społecznościowych.",
      isActive: "",
    },
  ]);

  const changeActiveYear = (year) => {
    //skopiowanie starych danych żeby nie operować na stanie bezpośrednio
    let newYears = [...years];
    //zaznacz użytkownikowi rok, który wybrał na osi czasu
    newYears[year].isActive = "active";
    setYearActive(newYears);
    for (let i = 0; i < years.length; i++) {
      //ukryj nieaktywne lata, wszystkie lata inne niż aktualnie wybrany rok
      if (i !== year) {
        //skopiowanie starych danych żeby nie operować na stanie bezpośrednio
        let newYears = [...years];
        newYears[i].isActive = "";
        setYearActive(newYears);
      }
    }
  };

  return (
    <>
      <div className="about-us">
        <div className="about-us-photo">
          {$(window).width() / parseFloat($("body").css("font-size")) <=
          "32" ? (
            <img src={imgHistorySmall} alt="" />
          ) : (
            <img src={imgHistory} alt="" />
          )}
          <h2>O nas</h2>
        </div>
        <div className="about-us-article">
          <h3>Nasza praca jest naszą pasją</h3>
          <article>
            Od 1980 roku wypiekamy torty, babki oraz ciasta - kontynuujemy
            dzieło rodziny Szczeblewskich działających zgodnie z zasadą
            tradycyjnych, naturalnych oraz świeżych wypieków.
          </article>
        </div>
      </div>
      <div className="owners">
        <figure>
          <img src={imgPlace} alt=""></img>
        </figure>
        <figure>
          <img src={imgOwner2} alt=""></img>
        </figure>
        <figure>
          <img src={imgOwner1} alt=""></img>
        </figure>
      </div>
      <div className="story">
        <article>
          Od 1980 roku wypiekamy torty, babki oraz ciasta - kontynuujemy dzieło
          rodziny Szczeblewskich działających zgodnie z zasadą tradycyjnych,
          naturalnych oraz świeżych wypieków. Na początku nasza pracownia
          znajdowała się w niewielkim lokalu przy ulicy Asnyka 18 gdzie dziś
          znajduje się pierwszy, najstarszy sklep firmowy. Dzięki wspólnej
          rodzinnej pracy oraz nieustannym dążeniu do celu i ogromnej pasji,
          stworzyliśmy markę posiadającą dwa własne sklepy firmowe oraz
          rozbudowaną sieć odbiorców naszych wyrobów na terenie między innymi:
          Wrocławia, Sycowa, Konina, Poznania oraz licznych miejscowości
          przyległych do Ostrowa Wielkopolskiego.<br></br>
          <br></br>Tradycja, naturalne i świeże wyroby wysokiej jakości - to
          produkty które powstają pod szyldem Ciastkarni Marcepanek. Zaczynając
          od podstawy dla każdego ciasta kończąc na dekoracjach naszym
          wyznacznikiem są wyselekcjonowane produkty których używamy do
          produkcji oraz oryginalne receptury które ulepszamy od samego początku
          naszego działania. Świeże owoce od lokalnych dostawców oraz wyszukane
          nowoczesne dekoracje projektowane przez naszych mistrzów cukiernictwa,
          to cechy personalizowanych tortów projektowanych pod życzenie każdego
          klienta. To właśnie Wy - klienci - jesteście dla nas priorytetem. To z
          myślą o Was pieczemy nasze smakołyki, które codziennie zachwycają
          smakiem Wasze podniebienia oraz przykuwają uwagę swoim oryginalnym
          wyglądem.<br></br>
          <br></br>W naszej ofercie znajdziecie:<br></br>
          <br></br> -ciasta i ciastka <br></br>-desery i tarty
          <br></br>-monoporcje <br></br>-regionalne i tradycyjne wypieki
          <br></br>-produkty przygotowywane na specjalne zamówienie<br></br>
          <br></br>
          Nasza praca jest naszą pasją dlatego wciąż się rozwijamy i poszerzamy
          swój asortyment o kolejne nowości które znajdziecie w naszych
          sklepach. Wyroby które tworzymy zdobywają coraz większe uznanie wśród
          klientów. Nasze produkty znajdziecie w sklepach firmowych.
          Współpracujemy również z renomowanymi marketami, restauracjami,
          hotelami i firmami w kraju oraz za granicą.
        </article>
      </div>
      <div className="timeline-wrapper">
        <h3>Miejsce z historią</h3>
        <section className="timeline">
          {years.map((y, index) => {
            return (
              <section key={index} className={`timeline-year ${y.isActive}`}>
                <div className="timeline-year-left">
                  <p>{y.year}</p>
                  <div className="timeline-year-line">
                    <div className="timeline-year-line-vertical"></div>
                    <span
                      onClick={() => changeActiveYear(index)}
                      className={`timeline-year-line-dot ${y.isActive}`}
                    ></span>
                  </div>
                  {/* <img src={years[0].img} alt=""></img> */}
                </div>
                <div className="timeline-year-right">
                  <div className="timeline-year-text">
                    <h4>{y.textHead}</h4>
                    <article>{y.text}</article>
                  </div>
                </div>
              </section>
            );
          })}
        </section>
      </div>
      <ContactUs />
      <Footer />
    </>
  );
};

export default AboutUsView;
