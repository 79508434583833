import React from "react";
import { withRouter } from "react-router-dom";
import "./SweetTradition.scss";
import Muszelki from "../../images/muszelki.jpg";
import CiastoCzekoladoweZWisnia from "../../images/sweet-tradition.png";

function SweetBuffet(props) {
  const nextPath = (path) => {
    props.history.push(path);
  };

  return (
    <section className="sweet-tradition">
      <img className="sweet-tradition-img1" src={Muszelki} />
      <img className="sweet-tradition-img2" src={CiastoCzekoladoweZWisnia} />
      <h2>Cukiernia z tradycją</h2>
      <article>
        Od 1980 roku wypiekamy torty, babki oraz ciasta. Tradycja, naturalne i
        świeże wyroby wysokiej jakości to produkty które powstają pod szyldem
        Ciastkarni Marcepanek. Naszym wyznacznikiem są wyselekcjonowane
        produkty, których używamy do produkcji, oraz oryginalne receptury, które
        ulepszamy od samego początku naszego działania.
      </article>
      <span onClick={() => nextPath("/Oferta")}>Więcej</span>
    </section>
  );
}

export default withRouter(SweetBuffet);
