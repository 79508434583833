import React from "react";
import "./styles.scss";
import imgCooper from "./../../images/offer.jpg";
import imgCooperSmall from "./../../images/offer-small.jpg";
import ContactUs from "../ContactUs/ContactUS";
import Footer from "../Footer/Footer";
import Shop from "../Shelf";
import $ from "jquery";

const ShopOfferView = () => {
  return (
    <>
      <div className="shop-offer">
        <div className="shop-offer-photo">
          {$(window).width() / parseFloat($("body").css("font-size")) <=
          "32" ? (
            <img src={imgCooperSmall} alt="" />
          ) : (
            <img src={imgCooper} alt="" />
          )}
          <h2>Oferta</h2>
        </div>
        <div className="shop-offer-article">
          <h3>Wypieki z myślą o kliencie</h3>
          <article>
            “Nasza praca jest naszą pasją dlatego wciąż się rozwijamy i
            poszerzamy swój asortyment o kolejne nowości, które znajdziecie w
            naszych sklepach. Klient jest dla nas priorytetem. To z myślą o nim
            pieczemy nasze smakołyki, które codziennie zachwycają smakiem
            podniebienia oraz przykuwają uwagę swoim oryginalnym wyglądem.”
          </article>
        </div>
      </div>
      <Shop />
      <ContactUs />
      <Footer />
    </>
  );
};

export default ShopOfferView;
