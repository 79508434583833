import React from "react";

import ShopOfferView from "./../components/ShopOfferView/ShopOfferView";

function OfferPage() {
  return (
    <div>
      <ShopOfferView />
    </div>
  );
}

export default OfferPage;
