import React, { useState, useEffect } from "react";
import "./styles.scss";
import Carousel from "@brainhubeu/react-carousel";
import "@brainhubeu/react-carousel/lib/style.css";
import $ from "jquery";
import { ReactReduxContext } from "react-redux";

const feedback = [
  {
    name: "Klaudia",
    desc:
      "Miałam wiele pomysłów i rozterek jakie ciasto oraz tort mam wybrać na wesele. Po rozmowie w Marcepanku ustaliliśmy najlepsze rozwiązanie, które okazało się super dla nas jak i dla naszych gości. Wesele wyszło świetnie!",
  },
  {
    name: "Grażyna",
    desc:
      "Przychodzę tu od roku 90’, zawsze zaskakują nowościami. Moja ulubiona cukiernia",
  },
  {
    name: "Artur",
    desc:
      "Zawsze świeże i zawsze smaczne od wielu lat. Lubię tutaj wracać. Polecam!",
  },
  {
    name: "Andrzej",
    desc:
      "Polecam, czuć w smaku, że to wyroby z prawdziwej cukierni od ludzi, którzy wiedzą co robią.",
  },
  {
    name: "Kinga",
    desc:
      "Szukaliśmy idealnej oferty na chrzciny mojego synka, wyszło przepysznie",
  },
  {
    name: "Agata",
    desc: "I to jest naturalne pieczywo! Drożdżówki na maśle - pycha!",
  },
  {
    name: "Elżbieta",
    desc:
      "Codzień z rana przyjeżdżam po świeże, jeszcze ciepłe pieczywo do popołudniowej kawy.",
  },

  {
    name: "Łukasz",
    desc:
      "Zawsze na ogromny plus. Zamawiamy z rodziną wypieki na każdą uroczystość w domu. Super.",
  },
  {
    name: "Renata",
    desc:
      "Na każde święta zamawiamy tutaj pieczywo. Od Wielkanocy, aż po Boże Narodzenie. To jest ten nasz smak!",
  },
  { name: "Konrad", desc: "Torcik na walentynki był super! Polecam." },
  { name: "Krystyna", desc: "Uwielbiam te maślane drożdżówki!" },
  {
    name: "Natalia",
    desc:
      "Byliśmy ostatnio na weselu ze słodkościami z Marcepanka. Przepyszne. Polecam.",
  },
  {
    name: "Karolina",
    desc:
      "Tort z okazji roczku dla naszej Julki był przepiękny. Smakował tak jak chcieliśmy.",
  },
  {
    name: "Weronika",
    desc:
      "Chciałam mieć inny tort niż wszystkie na 18stkach i zrealizowali. Super kontakt i zrozumienie klienta. Tort - przepyszny.",
  },
  {
    name: "Marta",
    desc: "Kocham, kocham, kocham!!! Najlepsi z najlepszych.",
  },
  {
    name: "Ryszard",
    desc:
      "Zamówiłem tort na dzień kobiet dla mojej żony i był znakomity. Wygląd i smak na najwyższym poziomie.",
  },
  {
    name: "Robert",
    desc:
      "Codziennie tu zaglądam. Pozdrawiam Panie ekspedientki, stały klient Robert.",
  },
  {
    name: "Maria",
    desc:
      "Polecam nie tylko za smak, ale za nowości. Zawsze zaskoczą jakąś nową propozycją.",
  },
  {
    name: "Dariusz",
    desc:
      "Obowiązkowe miejsce do odwiedzenia w Ostrowie. Zawsze wstępuje jak wracam do rodzinnego domu.",
  },
];

const ClientsPage = () => {
  const [slidesPerPage, setSlidesPerPage] = useState(1);
  useEffect(() => {
    if ($(window).width() / parseFloat($("body").css("font-size")) <= "32") {
      setSlidesPerPage(1);
    } else {
      setSlidesPerPage(5);
    }
  }, []);

  return (
    <div className="opinions-wrapper">
      <h2>Opinie klientów</h2>
      <section className="opinions">
        <Carousel arrows clickToChange slidesPerPage={slidesPerPage}>
          {feedback.map((fb, id) => {
            return (
              <div key={id} className="opinions-single">
                <article>{fb.desc}</article>
                <p>{fb.name}</p>
              </div>
            );
          })}
        </Carousel>
      </section>
    </div>
  );
};

export default ClientsPage;
