import { React } from "react";
import "./styles.scss";
import { withRouter } from "react-router-dom";

const ContactUs = (props) => {
  const nextPath = (path) => {
    props.history.push(path);
  };

  return (
    <div className="contact-us">
      <h4>Skontaktuj się z nami</h4>
      <span onClick={() => nextPath("/Kontakt")}>Skontaktuj się</span>
    </div>
  );
};

export default withRouter(ContactUs);
