import React, { useState } from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import Thumb from "../../../Thumb";
import "./styles.scss";
import Modal from "react-modal";
import $ from "jquery";

const customStyles = {
  content: {
    width:
      $(window).width() / parseFloat($("body").css("font-size")) <= "32"
        ? "78vw"
        : "50vw",
    maxHeight: "35vh",
    padding:
      $(window).width() / parseFloat($("body").css("font-size")) <= "32"
        ? "1rem 5%"
        : "5rem 5%",
    top: "50%",
    left: "50%",
    right: "auto",
    bottom: "auto",
    backgroundColor: "#8F512F",
    color: "#E8E4D9",
    borderRadius: "12px",
    fontSize:
      $(window).width() / parseFloat($("body").css("font-size")) <= "32"
        ? "1.1rem"
        : "2rem",
    transform: "translate(-50%, -50%)",
    zIndex: "3",
  },
};

const Product = ({ product }) => {
  const [modalIsOpen, setIsOpen] = useState(false);
  function openModal() {
    setIsOpen(true);
  }
  function closeModal() {
    setIsOpen(false);
  }

  return (
    <div className="shelf-item">
      <div className="shelf-item-wrapper">
        <Thumb
          classes="shelf-item__thumb"
          src={require(`./../../../../images/${product.mainImage}`).default}
          opacity="1"
          alt={product.name}
        />
        <p className="shelf-item__title">{product.name}</p>
        <span onClick={openModal}>Więcej</span>
      </div>
      <div className="modal-wrapper">
        <Modal
          style={customStyles}
          ariaHideApp={false}
          isOpen={modalIsOpen}
          onRequestClose={closeModal}
        >
          <article>{product.description}</article>
        </Modal>
      </div>
    </div>
  );
};

Product.propTypes = {
  product: PropTypes.object.isRequired,
};

export default connect()(Product);
