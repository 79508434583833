import React, { useState, useEffect } from "react";
import "./styles.scss";
import logo from "./../../images/logo_low.png";
import { withRouter } from "react-router-dom";
import Hamburger from "hamburger-react";

const Navbar = (props) => {
  const [aboutUsActive, setAboutUsActive] = useState("");

  const [offerActive, setOfferActive] = useState("");

  const [coopActive, setCoopActive] = useState("");

  const [isLocationHovered, setIsLocationHovered] = useState("");

  const [isContactHovered, setIsContactHovered] = useState("");

  const [isOpen, setOpen] = useState(false);
  const nextPath = (path) => {
    props.history.push(path);
  };

  const hideAndNavigate = (path) => {
    //hide links and white background
    setOpen(false);
    //and navigate to path
    nextPath(path);
  };

  useEffect(() => {
    if (window.location.href.indexOf("praca") > -1) {
      setCoopActive("active");
      setAboutUsActive("");
      setOfferActive("");
    } else if (window.location.href.indexOf("ONas") > -1) {
      setCoopActive("");
      setAboutUsActive("active");
      setOfferActive("");
    } else if (window.location.href.indexOf("Oferta") > -1) {
      setCoopActive("");
      setAboutUsActive("");
      setOfferActive("active");
    } else {
      setCoopActive("");
      setAboutUsActive("");
      setOfferActive("");
    }
    var prevScrollpos = window.pageYOffset;
    window.onscroll = function () {
      var currentScrollPos = window.pageYOffset;
      if (prevScrollpos > currentScrollPos) {
        if (document.getElementById("id-navbar-desktop") !== null) {
          document.getElementById("id-navbar-desktop").style.top = "0";
        }
        if (document.getElementById("id-navbar-mobile") !== null) {
          document.getElementById("id-navbar-mobile").style.top = "0";
        }
      } else {
        if (document.getElementById("id-navbar-desktop") !== null) {
          document.getElementById("id-navbar-desktop").style.top = "-1000px";
        }
        if (document.getElementById("id-navbar-mobile") !== null) {
          document.getElementById("id-navbar-mobile").style.top = "-1000px";
        }
      }
      prevScrollpos = currentScrollPos;
    };
  });

  useEffect(() => {
    const location = document.querySelector("[id='location']");
    const contact = document.querySelector("[id='contact-form-scroll']");
    const locationNavbarLink = document.querySelector(
      "[id='navbar-desktop-links-location']"
    );
    const contactNavbarLink = document.querySelector(
      "[id='navbar-desktop-links-contact']"
    );

    const sectionsToObserve = [];
    sectionsToObserve.push(location);
    sectionsToObserve.push(contact);

    if (sectionsToObserve[0] === null && sectionsToObserve[1] === null) {
      return;
    }
    const observer = new IntersectionObserver((entries) => {
      entries.forEach((entry) => {
        if (!entry.isIntersecting) {
          if (entry.target.id === "location") {
            locationNavbarLink.style.color = "#8f512f";
            locationNavbarLink.style.textDecoration = "none";
            locationNavbarLink.style.textUnderlineOffset = "unset";
          } else if (entry.target.id === "contact-form-scroll") {
            contactNavbarLink.style.color = "#8f512f";
            contactNavbarLink.style.textDecoration = "none";
            contactNavbarLink.style.textUnderlineOffset = "unset";
          }
        } else {
          if (entry.target.id === "location") {
            locationNavbarLink.style.color = "#4c5636";
            locationNavbarLink.style.textDecoration = "underline";
            locationNavbarLink.style.textUnderlineOffset = "5px";
            contactNavbarLink.style.color = "#8f512f";
            contactNavbarLink.style.textDecoration = "none";
            contactNavbarLink.style.textUnderlineOffset = "unset";
          } else if (entry.target.id === "contact-form-scroll") {
            contactNavbarLink.style.color = "#4c5636";
            contactNavbarLink.style.textDecoration = "underline";
            contactNavbarLink.style.textUnderlineOffset = "5px";
            locationNavbarLink.style.color = "#8f512f";
            locationNavbarLink.style.textDecoration = "none";
            locationNavbarLink.style.textUnderlineOffset = "unset";
          }
        }
      });
    }, {});
    sectionsToObserve.forEach((section) => observer.observe(section));
    return () => observer.disconnect();
  });

  return (
    <>
      <header id="id-navbar-desktop" className="navbar-desktop">
        <img
          onClick={() => nextPath("/")}
          className="navbar-desktop-links-logo"
          src={logo}
          alt=""
        ></img>
        <ul className="navbar-desktop-links">
          <li
            onClick={() => nextPath("/ONas")}
            className={`navbar-desktop-links-about ${aboutUsActive}`}
          >
            O Nas
          </li>
          <li
            onClick={() => nextPath("/Oferta")}
            className={`navbar-desktop-links-offer ${offerActive}`}
          >
            Oferta
          </li>
          <li
            id="navbar-desktop-links-location"
            onClick={() => nextPath("/Lokalizacje")}
            className={`navbar-desktop-links-location ${isLocationHovered}`}
            onMouseOver={() => setIsLocationHovered("hovered")}
            onMouseOut={() => setIsLocationHovered("")}
          >
            Lokalizacje
          </li>
          <li
            onClick={() => nextPath("/Współpraca")}
            className={`navbar-desktop-links-coop ${coopActive}`}
          >
            Współpraca
          </li>
          <li
            id="navbar-desktop-links-contact"
            onClick={() => nextPath("/Kontakt")}
            className={`navbar-desktop-links-contact ${isContactHovered}`}
            onMouseOver={() => setIsContactHovered("hovered")}
            onMouseOut={() => setIsContactHovered("")}
          >
            Kontakt
          </li>
        </ul>
      </header>
      <header id="id-navbar-mobile" className="navbar-mobile">
        <div className="navbar-mobile-header">
          <img
            onClick={() => hideAndNavigate("/")}
            className="navbar-mobile-header-logo"
            src={logo}
            alt=""
          ></img>
          <div className="navbar-mobile-header-hamburger">
            <Hamburger color="#b87239" toggled={isOpen} toggle={setOpen} />
          </div>
        </div>
        {isOpen ? (
          <ul className="navbar-mobile-links">
            <li
              onClick={() => hideAndNavigate("/ONas")}
              className="navbar-mobile-links-about"
            >
              O Nas
            </li>
            <li
              onClick={() => hideAndNavigate("/Oferta")}
              className="navbar-mobile-links-offer"
            >
              Oferta
            </li>

            <li
              onClick={() => hideAndNavigate("/Lokalizacje")}
              className="navbar-mobile-links-location"
            >
              Lokalizacje
            </li>
            <li
              onClick={() => hideAndNavigate("/Współpraca")}
              className="navbar-mobile-links-coop"
            >
              Współpraca
            </li>
            <li
              onClick={() => hideAndNavigate("/Kontakt")}
              className="navbar-mobile-links-contact"
            >
              Kontakt
            </li>
          </ul>
        ) : null}
      </header>
    </>
  );
};

export default withRouter(Navbar);
