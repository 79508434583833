import React from "react";
import PropTypes from "prop-types";
import QuantityFilter from "../Filter/QuantityFilter/index";
// import NameFilter from "../Filter/NameFilter/index";
import Sort from "../Sort";
import "./style.scss";

const ShelfHeader = (props) => {
  return (
    <>
      <p className="shelf-header-summary">
        {props.productsLength}/{props.productsListLength} produktów
      </p>
      <QuantityFilter />
      <Sort />
      {/* <NameFilter /> */}
    </>
  );
};

ShelfHeader.propTypes = {
  productsLength: PropTypes.number.isRequired,
};

export default ShelfHeader;
