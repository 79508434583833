import React from "react";
import "./styles.scss";
import imgLogo from "./../../images/logo_biale.png";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faFacebook, faInstagram } from "@fortawesome/free-brands-svg-icons";
import { withRouter } from "react-router-dom";

const Footer = (props) => {
  const followURL = (url) => {
    window.open(url, "_blank");
  };

  const nextPath = (path) => {
    props.history.push(path);
  };
  return (
    <div className="footer">
      <div className="footer-up">
        <section className="footer-up-logo-story">
          <img src={imgLogo} alt="" />
          <article>
            Słodkości z naturalnych składników oparte o tradycyjne receptury
            oraz nowoczesne inspiracje - pasjonujemy się cukiernictwem od 1980
            r.
          </article>
        </section>
        <section className="footer-up-offer">
          <h5>Oferta</h5>
          <div>
            <a onClick={() => nextPath("/Oferta")}>Torty</a>
            <a onClick={() => nextPath("/Oferta")}>Desery</a>
            <a onClick={() => nextPath("/Oferta")}>Ciasta i ciastka</a>
            <a onClick={() => nextPath("/Oferta")}>Tartaletki</a>
          </div>
        </section>
        <section className="footer-up-shop">
          <h5>Sklepy</h5>
          <div>
            <a onClick={() => nextPath("Lokalizacje")}>
              <p>Adama Asnyka 18,</p>
              <p>Ostrów Wielkopolski</p>
            </a>
            <a onClick={() => nextPath("Lokalizacje")}>
              <p>Raszkowska 26,</p>
              <p>Ostrów Wielkopolski</p>
            </a>
          </div>
        </section>
        <section className="footer-up-contact">
          <h5>Kontakt</h5>
          <div>
            <a onClick={() => nextPath("Kontakt")}>Formularz kontaktowy</a>
            <a href="tel:+627351580">Tel. +62 735 15 80</a>
            <div>
              <span
                onClick={() =>
                  followURL("https://www.facebook.com/ciastkarniamarcepanek")
                }
              >
                <FontAwesomeIcon icon={faFacebook} color="#E8E4D9" size="2x" />
              </span>
              <span
                onClick={() =>
                  followURL("https://www.instagram.com/ciastkarniamarcepanek")
                }
              >
                <FontAwesomeIcon icon={faInstagram} color="#E8E4D9" size="2x" />
              </span>
            </div>
          </div>
        </section>
      </div>
      <div className="footer-down">
        <a href="./Polityka prywatności.pdf" download>
          Polityka prywatności
        </a>
        <a href="./Regulamin.pdf" download>
          Regulamin strony
        </a>
      </div>
    </div>
  );
};
export default withRouter(Footer);
