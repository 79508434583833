import React, { useState, useEffect } from "react";
import "./styles.scss";
import formImg from "./../../images/czekoladoweZWisnia.jpg";

const FormView = () => {
  const [fName, setfName] = useState("");
  const [sName, setsName] = useState("");
  const [phone, setPhone] = useState("");
  const [email, setEmail] = useState("");
  const [subject, setSubject] = useState("");
  const [text, setText] = useState("");

  useEffect(() => {
    const script = document.createElement("script");
    script.src = "https://smtpjs.com/v3/smtp.js";
    script.async = true;
    document.body.appendChild(script);

    return () => {
      document.body.removeChild(script);
    };
  }, []);

  function handleSubmitButton() {
    var inputFields = document.querySelectorAll("[id*='required-field']");
    var i;
    for (i = 0; i < inputFields.length; i++) {
      if (inputFields[i].value.length === 0) {
        inputFields[i].setCustomValidity("Pole jest wymagane");
      } else {
        inputFields[i].setCustomValidity("");
      }
      if (
        inputFields[i].id === "required-field-5" &&
        inputFields[i].value.length > 0
      ) {
        handleEmailInput(inputFields[i]);
      }
    }
  }

  function handleEmailInput(inputField) {
    var re = /\S+@\S+\.\S+/;
    if (re.test(inputField.value) === false) {
      inputField.setCustomValidity(
        "Niepoprawny format e-mail, przykład: jan.nowak@gmail.com"
      );
    } else {
      inputField.setCustomValidity("");
    }
  }

  function handleSubmit(e) {
    e.preventDefault();
    window.Email.send({
      Host: "vps-91419065.vps.ovh.net",
      Username: "marcepanek",
      Password: "Twins=12",
      To: "ciastkarnia.marcepanek@gmail.com",
      From: "marcepanek@vps-91419065.vps.ovh.net",
      Subject: "Marcepanek.pl  - formularz kontaktowy od - " + email,
      Body:
        "Temat wiadomości: " +
        subject +
        "<br/><br/>Wiadomość: " +
        text +
        "<br/><br/>Imię i nazwisko: " +
        fName +
        " " +
        sName +
        "<br/><br/>Telefon: " +
        phone,
    });
    setEmail("");
    setfName("");
    setsName("");
    setPhone("");
    setSubject("");
    setText("");
    alert("E-mail został wysłany!");
  }

  return (
    <div id="contact-form" className="form-wrapper">
      <h2>Formularz kontaktowy</h2>
      <section className="form">
        <form onSubmit={handleSubmit} on className="form-inputs">
          <input
            id="required-field-1"
            type="text"
            value={fName}
            placeholder="Imię"
            className="name"
            required
            onChange={(e) => setfName(e.target.value)}
          />
          <input
            id="required-field-2"
            type="text"
            value={sName}
            placeholder="Nazwisko"
            className="surname"
            required
            onChange={(e) => setsName(e.target.value)}
          />
          <input
            id="required-field-5"
            type="email"
            value={email}
            placeholder="Adres E-mail"
            className="email"
            required
            onChange={(e) => setEmail(e.target.value)}
          />
          <input
            type="text"
            value={phone}
            placeholder="Telefon"
            className="phone"
            onChange={(e) => setPhone(e.target.value)}
          />
          <input
            id="required-field-3"
            type="text"
            value={subject}
            placeholder="Temat"
            className="subject"
            required
            onChange={(e) => setSubject(e.target.value)}
          />
          <textarea
            id="required-field-4"
            type="text"
            value={text}
            placeholder="Treść wiadomości"
            className="message"
            required
            onChange={(e) => setText(e.target.value)}
          />
          <input
            id="contact-form-scroll"
            onClick={handleSubmitButton}
            type="submit"
            value="Wyślij"
          ></input>
        </form>
        <section className="form-photo">
          <img src={formImg} alt="" />
          <p>Skontaktuj się telefonicznie</p>
          <a href="tel:+627351580">+62 735 15 80</a>
        </section>
      </section>
    </div>
  );
};

export default FormView;
