import React from "react";
import "./styles.scss";
import imgCooper from "./../../images/coop.jpg";
import imgCooperSmall from "./../../images/coop-small.jpg";
import AboutUsLeft from "./../../images/about-us-left.jpg";
import AboutUsRight from "./../../images/about-us-right.jpg";
import ContactUs from "../ContactUs/ContactUS";
import Footer from "../Footer/Footer";
import $ from "jquery";

const CoopView = () => {
  return (
    <>
      <div className="co-op">
        <div className="co-op-photo">
          {$(window).width() / parseFloat($("body").css("font-size")) <=
          "32" ? (
            <img src={imgCooperSmall} alt="" />
          ) : (
            <img src={imgCooper} alt="" />
          )}
          <h2>Współpraca</h2>
        </div>
        <div className="co-op-article">
          <h3>Współpraca z firmami</h3>
          <h3>w Polsce i za granicą</h3>
          <article>
            Nasza praca jest naszą pasją dlatego wciąż się rozwijamy i
            poszerzamy swój asortyment o kolejne nowości które znajdziecie w
            naszych sklepach. Wyroby które tworzymy zdobywają coraz większe
            uznanie wśród klientów. Nasze produkty znajdziecie w sklepach
            firmowych. Współpracujemy również z renomowanymi marketami,
            restauracjami, hotelami i firmami w kraju oraz za granicą. Jeśli
            myślisz o współpracy z nami, zapraszamy do kontaktu poprzez
            formularz kontaktowy online lub telefonicznie.
          </article>
        </div>
        <div className="co-op-images">
          <img src={AboutUsLeft} alt="" />
          <img src={AboutUsRight} alt="" />
        </div>
      </div>
      <ContactUs />
      <Footer />
    </>
  );
};

export default CoopView;
