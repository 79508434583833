export const groups = [
  "Monoporcje",
  "Torty",
  "Tartaletki",
  "Ciastka",
  "Ciasta",
];
export const units = ["szt", "litr", "kg", "opak", "m2", "mb"];
export const apply = [];
export const subGroups = [];
export const producers = [];

export const formatPrice = (x, currency) => {
  switch (currency) {
    case "BRL":
      return x.toFixed(2).replace(".", ",");
    default:
      try {
        return x.toFixed(2);
      } catch {
        return "0.00";
      }
  }
};

export const prefixToAdd = "";

export const productsAPI = "";
// 'https://react-shopping-cart-67954.firebaseio.com/products.json';
// "https://slaskie-centrum-stolarskie.firebaseio.com/products.json";
// "https://firestore.googleapis.com/v1/projects/slaskie-centrum-stolarskie/databases/(default)/documents/produkty?pageSize=100000";
// export const productsAPI = "http://localhost:8001/api/products";
