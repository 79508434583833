import React, { useEffect } from "react";
import { scroller } from "react-scroll";
import OfferPage from "../components/OfferView/OfferPage";
import Footer from "../components/Footer/Footer";
import ClientsPage from "../components/ClientsView/Clients";
import LocsPage from "../components/Localizations/Localizations";
import FormView from "../components/FormView/Form";
import NewsView from "../components/NewsView/NewsPage";
import SweetBuffet from "../components/SweetBuffet/SweetBuffet";
import SweetTradition from "../components/SweetTradition/SweetTradition";
import $ from "jquery";

const scrollToSection = (style) => {
  $(window).width() / parseFloat($("body").css("font-size")) <= "32"
    ? scroller.scrollTo(style, {
        duration: 200,
        delay: 0,
        smooth: "easeInOutQuart",
        isDynamic: true,
        offset: -50,
      })
    : scroller.scrollTo(style, {
        duration: 200,
        delay: 0,
        smooth: "easeInOutQuart",
        isDynamic: true,
        offset: -100,
      });
};

const handleScrollToElem = (locPath) => {
  if (locPath.includes("Kontakt")) {
    scrollToSection("contact-form");
  } else if (locPath.includes("Lokalizacje")) {
    scrollToSection("location");
  }
};

function MainPage() {
  useEffect(() => {
    handleScrollToElem(window.location.pathname);
  });
  return (
    <>
      <SweetBuffet />
      <SweetTradition />
      <OfferPage />
      <ClientsPage />
      <LocsPage />
      <FormView />
      <NewsView />
      <Footer />
    </>
  );
}

export default MainPage;
